<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-section">
          <b-form v-on:submit.prevent="onSubmit" v-if="show">
            <b-form-group
              id="input-group-1"
              label="Text:"
              label-for="input-1"
              description="Paste full text of daily horoscope..."
            >
              <b-form-textarea
                id="textarea"
                v-model="text"
                style="height:350px"
                required
                max-rows="100"
              ></b-form-textarea>
            </b-form-group>
            <div class="form-submit">
              <b-button type="submit" variant="secondary">Submit</b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :pagination-options="{
            enabled: true,
            perPage: 20,
            perPageDropdown: [20],
            dropdownAllowAll: false
          }"
          :rows="rows"
          :columns="columns"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <div>
                <router-link
                  :to="{
                    name: 'dailyHoroscopeForm',
                    params: { id: props.formattedRow.id }
                  }"
                >
                  <b-button variant="outline-primary">Edit</b-button>
                </router-link>

                <b-button
                  @click="confirmDelete(props.formattedRow)"
                  variant="outline-danger"
                  >Remove</b-button
                >
              </div>
            </span>
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import moment from "moment";

export default {
  data() {
    return {
      text: "",
      show: true,
      isLoading: true,
      columns: [
        {
          label: "ID",
          field: "id"
        },
        {
          label: "Prediction Date",
          field: "predictionDate",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "MMM dd yyyy"
        },
        {
          label: "Creation Date",
          field: "createdAt",
          sortable: false
        },
        {
          label: "Update Date",
          field: "updatedAt",
          sortable: false
        },
        {
          label: "Actions",
          field: "actions",
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        attributes: "id,predictionDate,createdAt,updatedAt",
        sort: {
          field: "id",
          type: "DESC"
        },
        page: 1,
        perPage: 20
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Daily Horoscope",
        actions: [
          {
            to: "/dailyHoroscopeForm",
            name: "New Horoscope",
            btnClass: "btn-success"
          }
          //,{
          //   to: "/dailyHoroscopeBuilder",
          //   name: "Horoscope Builder",
          //   btnClass: "btn-primary"
          // }
        ]
      }
    ]);
    this.loadItems();
  },
  methods: {
    confirmDelete(row) {
      if (confirm(`Are you sure you want to delete ${row.predictionDate}?`)) {
        return new Promise(resolve => {
          ApiService.post("dailyHoroscope/delete", { id: row.id })
            .then(({ data }) => {
              if (data && data.response && data.response.status) {
                this.$bvToast.toast("It has been removed succesfully!", {
                  title: "Success",
                  variant: "success"
                });
              }
              this.loadItems();
              resolve();
            })
            .catch(e => {
              console.log(e.message);
            });
        });
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    // load items is what brings back the rows from server
    loadItems() {
      this.isLoading = true;
      return new Promise(resolve => {
        ApiService.get("dailyHoroscope", this.serverParams)
          .then(({ data }) => {
            let items = data.response.items;
            items.map(item => {
              item.createdAt = moment(item.createdAt).format("MM/DD/YYYY LT");
              item.updatedAt = moment(item.updatedAt).format("MM/DD/YYYY LT");
            });

            this.rows = items;
            this.totalRecords = data.response.itemCount;
            this.isLoading = false;
            resolve();
          })
          .catch(e => {
            console.log(e.message);
          });
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      return new Promise(resolve => {
        ApiService.post("dailyHoroscope", this.text)
          .then(({ data }) => {
            if (data && data.response && data.response.status) {
              this.$bvToast.toast("Horoscope has been saved sucessfully!", {
                title: "Success",
                variant: "success"
              });
              this.text = "";
              this.loadItems();
            } else {
              this.$bvToast.toast(
                "Something went wrong while inserting horoscope...",
                {
                  title: "Error",
                  variant: "danger"
                }
              );
            }
            resolve();
          })
          .catch(({ response }) => {
            let message = "Something went wrong while inserting horoscope...";
            if (
              response &&
              response.data &&
              response.data.error &&
              response.data.error.message
            ) {
              message = response.data.error.message;
            }
            this.$bvToast.toast(message, {
              title: "Error",
              variant: "danger"
            });
          });
      });
    }
  }
};
</script>
