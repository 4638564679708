var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "div",
          { staticClass: "form-section" },
          [
            _vm.show
              ? _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.onSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "input-group-1",
                          label: "Text:",
                          "label-for": "input-1",
                          description: "Paste full text of daily horoscope...",
                        },
                      },
                      [
                        _c("b-form-textarea", {
                          staticStyle: { height: "350px" },
                          attrs: {
                            id: "textarea",
                            required: "",
                            "max-rows": "100",
                          },
                          model: {
                            value: _vm.text,
                            callback: function ($$v) {
                              _vm.text = $$v
                            },
                            expression: "text",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-submit" },
                      [
                        _c(
                          "b-button",
                          { attrs: { type: "submit", variant: "secondary" } },
                          [_vm._v("Submit")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("vue-good-table", {
            attrs: {
              mode: "remote",
              totalRows: _vm.totalRecords,
              isLoading: _vm.isLoading,
              "pagination-options": {
                enabled: true,
                perPage: 20,
                perPageDropdown: [20],
                dropdownAllowAll: false,
              },
              rows: _vm.rows,
              columns: _vm.columns,
            },
            on: {
              "on-page-change": _vm.onPageChange,
              "on-sort-change": _vm.onSortChange,
              "on-column-filter": _vm.onColumnFilter,
              "on-per-page-change": _vm.onPerPageChange,
              "update:isLoading": function ($event) {
                _vm.isLoading = $event
              },
              "update:is-loading": function ($event) {
                _vm.isLoading = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "table-row",
                fn: function (props) {
                  return [
                    props.column.field == "actions"
                      ? _c("span", [
                          _c(
                            "div",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "dailyHoroscopeForm",
                                      params: { id: props.formattedRow.id },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    { attrs: { variant: "outline-primary" } },
                                    [_vm._v("Edit")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "outline-danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmDelete(
                                        props.formattedRow
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Remove")]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(props.formattedRow[props.column.field])
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }